import React from 'react';
import css from './Before.module.css';
import Shade from '../../assets/shade.png';
import ReactCompareImage from 'react-compare-image';
import First from '../../assets/before.png';
import After from '../../assets/after.png';


const Before = () => {
    return (
        <div className={css.Before}>
            <div className={css.left}>
                <span>Virtual Try-on</span>
                <span>NEVER BUY THE WRONG SHADE AGAIN!</span>
                <span>Try Now!</span>
                <img src={Shade} alt="" />
            </div>

            <div className={css.right}>
                <div className={css.wrapper}>
                    <ReactCompareImage leftImage={First} rightImage={After} />
                </div>
            </div>
        </div>
    )
}

export default Before;
